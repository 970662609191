/* Usage example
 * -- node - must by a valid JS selector eg. $componentElement[0]
 * -- cb - it'a a callback anonymous function with parameter [node],
 *    should be used to get to the shadowRoot of <cms-menu/> from the other
 *    component X (e.g. component header)
 * CmsMenuUtils.onMenuRender(node, function(node) {
 *   // node -, reference for this <cms-menu/> webComponent,
 *   // $(node).find('a')...
 * });
 */
const CmsMenuUtils = {
    observerMenuByLocationChange: function(node, cb) {
        node.addEventListener('cms-menu-render', function() {
            cb(node);
        });
    },
    onMenuRender: function(node, cb) {

        const warnForNode = 'CmsMenuUtils: node paramater must be an object.',
              warnForCb = 'CmsMenuUtils: cb parameter must by an function.',
              loaded = 'loaded';

        if (typeof node !== 'object') return console.warn(warnForNode);
        if (typeof cb !== 'function') return console.warn(warnForCb);

        if (node.hasAttribute(loaded) && node.getAttribute(loaded) === 'yes') {
            cb(node);
        }
        this.observerMenuByLocationChange(node, cb);
    },
};
